<template>
  <div class="page-box">
    <div class="form-content">
      <a-spin :spinning="loading">
        <a-form-model
          ref="formModelRef"
          :model="form"
          :rules="rules"
          v-bind="formItemLayout"
        >
          <a-form-model-item label="开始时间" prop="beginTime">
            <a-date-picker
              v-model="form.beginTime"
              :show-time="{ format: 'HH:mm', defaultValue: moment().startOf('d') }"
              format="YYYY-MM-DD HH:mm"
              value-format="YYYY-MM-DD HH:mm:[00]"
              placeholder="请选择"
              :disabled-date="disabledBeginDate"
              :disabled-time="disabledBeginTime"
            />
          </a-form-model-item>
          <a-form-model-item label="结束时间" prop="endTime">
            <a-date-picker
              v-model="form.endTime"
              :show-time="{ format: 'HH:mm', defaultValue: moment().endOf('d') }"
              format="YYYY-MM-DD HH:mm"
              value-format="YYYY-MM-DD HH:mm:[59]"
              placeholder="请选择"
              :disabled-date="disabledEndDate"
              :disabled-time="disabledEndTime"
            />
          </a-form-model-item>
          <a-form-model-item
            label="广告图片"
            prop="picUrl"
            extra="建议尺寸750x1200像素"
          >
            <picture-card-upload :fileList="form.picUrl?[form.picUrl]:[]" @update:fileList='res=>form.picUrl=res.length?res[0]:""' :max="1" />
          </a-form-model-item>
          <a-form-model-item
            label="广告链接"
            prop="appLinkId"
          >
            <span v-if="form.appLinkId" class="select-result">已选择：{{linkSelected.name}}</span>
            <a-button
              type="link"
              style="padding:0"
              @click="linkListShow = true"
            >
              {{form.appLinkId ? '点击更换' : '点击选择'}}
            </a-button>
          </a-form-model-item>
          <a-form-model-item
            label="链接参数"
            prop="linkParam"
            extra="请先选择广告链接"
          >
            <span v-if="form.linkParam" class="select-result">已选择：{{linkParamSelected.name}}</span>
            <a-button
              type="link"
              style="padding:0"
              @click="linkParamsShow = true"
              :disabled="!form.appLinkId"
            >
              {{form.linkParam ? '点击更换' : '点击选择'}}
            </a-button>
          </a-form-model-item>
          <a-form-model-item
            label="备注"
            prop="remark"
          >
            <a-textarea type="textaraea" placeholder="请输入" v-model="form.remark" />
          </a-form-model-item>
          <a-row>
            <a-col :span="formItemLayout.labelCol.span"></a-col>
            <a-col :span="formItemLayout.wrapperCol.span" class="btn-box">
              <a-button
                type="primary"
                :loading="submitting"
                @click="handleSubmit"
              >
                保存
              </a-button>
              <a-button @click="$closeCurrentView">关闭</a-button>
            </a-col>
          </a-row>
        </a-form-model>
      </a-spin>
    </div>
    <link-list :show.sync="linkListShow" :code="linkSelected.code" @change="handleLinkChange" />
    <link-params :show.sync="linkParamsShow" :code="linkSelected.code" :name="linkParamSelected.name" @change="handleLinkParamChange" />
  </div>
</template>

<script>
import { ref, onMounted } from '@vue/composition-api'
import { shop } from '@/api'
import moment from 'moment'
import LinkList from './LinkList'
import LinkParams from './LinkParams'

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

export default {
  name: 'PageAdvert',

  components: {
    LinkList,
    LinkParams
  },

  setup (props, { root }) {
    const loading = ref(false)
    onMounted(() => {
      getDetail()
    })
    const adMaterielId = ref('')
    async function getDetail () {
      loading.value = true
      const { code, msg, data } = await shop.getAdvertDetail()
      loading.value = false
      if (code === '00000') {
        if (data) {
          adMaterielId.value = data.adMaterielId
          Object.keys(form.value).forEach(item => {
            form.value[item] = data[item]
          })
          linkSelected.value = {
            name: data.appLinkName,
            code: data.appLinkCode
          }
          linkParamSelected.value = JSON.parse(data.linkParam)
        }
      } else {
        root.$message.error(msg || '广告详情获取失败，请稍后重试')
      }
    }

    const formModelRef = ref(null)
    const form = ref({
      beginTime: '',
      endTime: '',
      picUrl: '',
      appLinkId: '',
      linkParam: '',
      remark: ''
    })
    const rules = ref({
      beginTime: [
        { required: true, message: '请选择' }
      ],
      endTime: [
        { required: true, message: '请选择' }
      ],
      picUrl: [
        { required: true, message: '请上传' }
      ],
      appLinkId: [
        { required: true, message: '请选择', trigger: 'onBlur' }
      ],
      linkParam: [
        { required: true, message: '请选择', trigger: 'onBlur' }
      ],
      remark: [
        { max: 255, message: '您可以尝试输入简短一些' }
      ]
    })

    function disabledBeginDate (current) {
      if (!form.value.endTime) return false
      return moment(form.value.endTime).isBefore(current)
    }
    function disabledBeginTime () {
      return {
        disabledHours: () => form.value.endTime ? range(Number(moment(form.value.endTime).format('HH')) + 1, 24) : [],
        disabledMinutes: () => {
          if (!form.value.endTime || !form.value.beginTime) return []
          return moment(form.value.endTime).format('HH') === moment(form.value.beginTime).format('HH') ? range(Number(moment(form.value.endTime).format('mm')), 59) : []
        }
      }
    }
    function disabledEndDate (current) {
      if (!form.value.beginTime) return false
      return moment(form.value.beginTime).isAfter(current)
    }
    function disabledEndTime () {
      return {
        disabledHours: () => form.value.beginTime ? range(0, Number(moment(form.value.beginTime).format('HH'))) : [],
        disabledMinutes: () => {
          if (!form.value.endTime || !form.value.beginTime) return []
          return moment(form.value.endTime).format('HH') === moment(form.value.beginTime).format('HH') ? range(0, Number(moment(form.value.beginTime).format('mm')) + 1) : []
        }
      }
    }

    function range (start, end) {
      const result = []
      for (let i = start; i < end; i++) {
        result.push(i)
      }
      return result
    }

    const linkListShow = ref(false)
    const linkSelected = ref({})
    function handleLinkChange (e) {
      if (e.appLinkId !== form.value.appLinkId) {
        form.value.appLinkId = e.appLinkId
        linkSelected.value = e
        linkListShow.value = false
        form.value.linkParam = ''
        linkParamSelected.value = {}
        formModelRef.value.validateField('appLinkId')
      }
    }

    const linkParamsShow = ref(false)
    const linkParamSelected = ref({})
    function handleLinkParamChange (e) {
      form.value.linkParam = JSON.stringify(e)
      linkParamSelected.value = e
      linkParamsShow.value = false
      formModelRef.value.validateField('linkParam')
    }

    const submitting = ref(false)
    async function handleSubmit () {
      if (submitting.value) return
      formModelRef.value.validate(async valid => {
        if (!valid) return
        submitting.value = true
        const { code, msg } = await shop.updateAdvert({
          ...form.value,
          ...(adMaterielId.value ? { adMaterielId: adMaterielId.value } : {})
        })
        submitting.value = false
        if (code === '00000') {
          root.$message.success('保存成功')
        } else {
          root.$message.error(msg || '保存失败，请稍后重试')
        }
      })
    }

    return {
      moment,
      formItemLayout,
      loading,
      formModelRef,
      form,
      rules,
      disabledBeginDate,
      disabledBeginTime,
      disabledEndDate,
      disabledEndTime,
      linkListShow,
      linkSelected,
      handleLinkChange,
      linkParamsShow,
      linkParamSelected,
      handleLinkParamChange,
      submitting,
      handleSubmit
    }
  }
}
</script>

<style lang="less" scoped>
.page-box {
  position: relative;
  .form-content {
    width: 600px;
  }
}
.btn-box {
  ::v-deep .ant-btn {
    margin-right: 12px;
    &:last-child {
      margin: 0;
    }
  }
}
.select-result {
  display: block;
  margin-top: 10px;
  margin-bottom: -10px;
  line-height: 22px;
}
</style>
