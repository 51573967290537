<template>
  <div>
    <div class="custom-mask" v-if="show" @click="$emit('update:show', false)"></div>
    <div class="link-list" :class="{show: show}">
      <div class="content">
        <p class="header">广告链接</p>
        <div class="list-box">
          <a-list item-layout="horizontal" :loading="loading" :data-source="linkList">
            <a-list-item slot="renderItem" slot-scope="item" :class="{active: item.code === code}">
              <a-list-item-meta
                :title="item.name"
                :description="item.linkUrl"
              />
              <div slot="actions">
                <a-button type="link" style="padding-right:0" @click="$emit('change', item)">选择</a-button>
              </div>
            </a-list-item>
          </a-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { watch, ref } from '@vue/composition-api'
import { common } from '@/api'

export default {
  name: 'LinkList',

  props: {
    show: Boolean,
    code: String
  },

  setup (props) {
    const loading = ref(false)
    const linkList = ref([])
    watch(() => props.show, data => {
      if (!linkList.value.length) getData()
    })
    async function getData () {
      loading.value = true
      const { data } = await common.getAppLinkList()
      loading.value = false
      linkList.value = data || []
    }

    return {
      loading,
      linkList
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  padding: 16px 24px;
  border-bottom: 1px solid #e8e8e8;
  color: rgba(0,0,0,.85);
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
}
.custom-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.link-list {
  position: fixed;
  top: 104px;
  right: 0;
  bottom: 0;
  z-index: 2;
  width: 0;
  overflow: hidden;
  transition: all .3s;
  box-shadow: -2px 0 8px rgba(0,0,0,.15);
  background-color: #fff;
  &.show {
    width: 300px;
  }
  .content {
    width: 300px;
    height: 100%;
  }
  .list-box {
    height: calc(100% - 55px);
    padding: 0 20px;
    overflow-y: auto;
  }
  ::v-deep .ant-spin-nested-loading,
  ::v-deep .ant-spin-container {
    height: 200px;
  }
  ::v-deep .active {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      top: 20px;
      left: -10px;
      z-index: 1;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background-color: @main-color;
    }
  }
}
</style>
